@import "../variables.scss";

.SubNav {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 6px;
  flex: 1;
  min-height: 100%;

  a {
    width: 100%;
    padding: 3px;
    height: 200px;
    transform: scale(0.9);
    transition: transform 100ms;

    .content {
      position: relative;
      width: 100%;
      text-align: center;
      background-image: linear-gradient(90deg, $secondary 0%, $primary 100%);
      background-position: 0;
      background-size: cover;
      border-radius: 4px;
      height: 100%;
      overflow: hidden;

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.9) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        color: white;
        width: 100%;
        font-size: larger;
        height: 100%;
      }
    }

    &:hover {
      transform: scale(1);

      .content .text {
        text-decoration: none;
      }
    }
  }

  @media (min-width: 425px) {
    a {
      width: 50%;
    }
  }

  @media (min-width: 768px) {
    a {
      width: 33%;
    }
  }

  @media (min-width: 1024px) {
    a {
      width: 25%;
    }
  }
}

@font-face {
  font-family: "allerregular";
  src: url("fonts/aller_rg-webfont.woff2") format("woff2"),
    url("fonts/aller_rg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "allerbold";
  src: url("fonts/aller_bd-webfont.woff2") format("woff2"),
    url("fonts/aller_bd-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "allerbold_italic";
  src: url("fonts/aller_bdit-webfont.woff2") format("woff2"),
    url("fonts/aller_bdit-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "alleritalic";
  src: url("fonts/aller_it-webfont.woff2") format("woff2"),
    url("fonts/aller_it-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "aller_lightregular";
  src: url("fonts/aller_lt-webfont.woff2") format("woff2"),
    url("fonts/aller_lt-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "aller_lightitalic";
  src: url("fonts/aller_ltit-webfont.woff2") format("woff2"),
    url("fonts/aller_ltit-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "aller_displayregular";
  src: url("fonts/allerdisplay-webfont.woff2") format("woff2"),
    url("fonts/allerdisplay-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "allerregular", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "./variables.scss";

.card .nav-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

video {
  max-width: 450px;
}

@media only screen and (max-width: 767px) {
  video {
    max-width: 100%;
  }
}

@import "~bootstrap/scss/bootstrap.scss";

h1 {
  font-family: "allerbold_italic";
  letter-spacing: "-2.25%";
}

h2 {
  font-family: "aller_lightregular";
  text-transform: uppercase;
}

body {
  font-family: "allerregular";
}

.Section {
  &:nth-child(2n) {
    .row {
      flex-direction: row-reverse;
    }
  }
}

.image-col {
  @media only screen and (max-width: 767px) {
    > .carousel {
      display: block;
    }

    > img {
      display: none;
    }
  }
}

.carousel {
  display: none;
}

@media only screen and (max-width: 767px) {
  .carousel {
    display: block;
  }
}

$primary: #b42460;
$secondary: #246ebf;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "dark": rgba(#000, 0.8),
);

$body-bg: #363845;
$body-color: #fff;

$link-color: lighten($secondary, 15%);
$link-hover-color: $primary;

.InstaFeed {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 6px;
  flex: 1;
  min-height: 100%;

  a {
    width: 100%;
    padding: 6px;

    .content {
      position: relative;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
      }

      .insta-caption {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.9) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        bottom: 0;
        color: white;
        width: 100%;
      }
    }

    &:hover .content .insta-caption {
      white-space: normal;
    }
  }

  @media (min-width: 425px) {
    a {
      width: 50%;
    }
  }

  @media (min-width: 768px) {
    a {
      width: 33%;
    }
  }

  @media (min-width: 1024px) {
    a {
      width: 25%;
    }
  }
}

.CircleImage {
  position: relative;
  padding-top: 100%;

  video,
  img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

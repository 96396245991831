.Header {
  padding-top: 72px;
  background-color: "#000";
  background-repeat: no-repeat;
  background-position-x: center;
  background-attachment: scroll;
  background-size: cover;
  overflow: hidden;
  position: relative;

  .background {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    > img,
    > video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: "center center";
    }
  }

  .content {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) 10%,
      rgba(0, 0, 0, 0) 100%
    );
    text-align: center;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 10vw;
    }

    h2 {
      font-size: 8vw;
    }
  }
}

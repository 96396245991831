.admin-padding {
  padding-top: 72px;
  display: flex;
  flex: 1;

  .container {
    display: flex;
    flex-direction: column;

    > .row {
      flex: 1;
    }
  }
}
